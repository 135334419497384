<template>
    <div>
        <!-- Field Name -->
        <validation-observer ref="personalInfoForm">
        <b-card>
            <!-- form -->
            <b-form>
                <b-row>
                    <!-- Name -->
                    <b-col md="3">
                        <b-form-group label="Field Name">
                        <validation-provider
                            #default="{ errors }"
                            name="field name"
                            rules="required"
                        >
                            <b-form-input
                                v-model="fieldName"
                                :disabled="true"
                                :state="errors.length > 0 ? false:null"
                                placeholder="Enter Text"
                                @blur="fieldName=fieldName.trim()"
                            />
                            <small class="text-danger" v-if="!fieldAlreadyExists">{{ errors[0] }}</small>
                            <small class="text-danger" v-else>Field name alredy exists.</small>
                        </validation-provider>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-form>
        </b-card>

        <!-- Courses -->
        <b-card>
            <!-- form -->
            <b-form>
                <b-table
                 responsive 
                 :items="courses" 
                 :fields="tableColumns" 
                 show-empty 
                 empty-text="No matching records found" 
                 class="edit_course position-relative">

                    <!-- Column : Course Name -->
                    <template #cell(course_name)="data">
                        <validation-provider
                            #default="{ errors }"
                            name="course name"
                            :vid="'course name'+data.index"
                            rules="required"
                        >
                            <b-form-input
                                v-model="data.item.name"
                                :state="errors.length > 0 ? false:null"
                                placeholder="Enter Text"
                                style="width:492px"
                                :disabled="!data.item.fromLocal"
                                @blur="data.item.name=data.item.name.trim()"
                            />
                            <small class="text-danger" v-if="!alreadyExists(data.item.name,data.index)">{{ errors[0] }}</small>
                            <small class="text-danger" v-else>Course already exists.</small>
                        </validation-provider>
                    </template>

                    <!-- Column : Action -->
                    <template #cell(action)="data">
                        <div style="white-space: nowrap;display:flex;justify-content: end;align-items: center;" v-if="$route.meta.action=='write' || $route.meta.action==undefined">
                            <img src='@/assets/images/erflog/Delete.png' v-if="data.item.fromLocal == true" style="cursor:pointer"  @click.prevent="deleteRow(data.index,data.item,false)" class="action_icon"/>
                            <!-- <img src='@/assets/images/erflog/enable_new.png' v-if="!data.item.isCoursesDeleted && data.item.fromLocal == false" style="cursor:pointer"  @click.prevent="deleteRow(data.index,data.item,true)" class="action_icon"/>
                            <img src='@/assets/images/erflog/disable_new.png' v-if="data.item.isCoursesDeleted && data.item.fromLocal == false" style="cursor:pointer"  @click.prevent="deleteRow(data.index,data.item,true)" class="action_icon"/> -->
                            <b-icon-toggle-on class="action_icon focusRemoveToggle" variant="success" width="30px" height="22px" v-if="!data.item.isCoursesDeleted && data.item.fromLocal == false" style="cursor:pointer"  @click.prevent="deleteRow(data.index,data.item,true)"></b-icon-toggle-on>
                            <b-icon-toggle-off class="action_icon focusRemoveToggle" variant="secondary" width="30px" height="22px" v-if="data.item.isCoursesDeleted && data.item.fromLocal == false" style="cursor:pointer"  @click.prevent="deleteRow(data.index,data.item,true)"></b-icon-toggle-off>
                        </div>
                        <div v-else>
                          --
                        </div>
                    </template>
                </b-table>
                <div class="add_edu_link">
                    <a @click.prevent="addRow()">Add New Course</a>
                </div>
            </b-form>
        </b-card>

        </validation-observer>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard,BFormInput, BFormGroup, BForm, BRow, BCol, BButton,BTable,BIconToggleOff,BIconToggleOn
} from 'bootstrap-vue'
import firebase from "@/utils/firebaseInit.js";
import firebaseTimeStamp from "firebase";
import { extend } from 'vee-validate';
import { required, email,max,max_value,integer,numeric,min,min_value,alpha_spaces,double } from 'vee-validate/dist/rules';

import { Country, State, City }  from 'country-state-city';

extend('required',required)
extend('max',max)
extend('min',min)
extend('min_value',min_value)
extend('email',email)
extend('alpha_spaces',alpha_spaces)
extend('max_value',max_value)
extend('integer',integer)
extend('numeric',numeric)
extend('double',double)

import { dbCollections } from "@/utils/firebaseCollection.js";
import { uploadImageFile } from '@/utils/FirebaseQueries/storageQuery/storageQueries'
import { ValidationFunction } from '@/utils/globalValidations.js';

import vSelect from 'vue-select'

const db = firebase.firestore()

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        BCard,
        BFormInput,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BButton,
        BTable,
        vSelect,
        BIconToggleOn,
        BIconToggleOff
    },
    data() {
        return {
            fieldName:'',
            currentFieldName:'',
            fields:[],
            courses:[],
            allCollges:[],
            tableColumns:['course_name','action'],
            docId:this.$route.params.id,
            fieldUsedCollege:[],
            dbCourseArray: []
        }
    },
    created() {
        var self = this

        if(!self.docId)
        {
            self.$router.push({name:'field-course-mgmt'})
        }

        self.$root.$on('saveNewFieldCorse',()=>{
            console.log('save called')
            if(self.$refs.personalInfoForm)
            {
                self.saveFieldCourse()
            }
        })
        self.$root.$on('cancelNewFieldCourse',()=>{
            console.log('close called')
            if(self.$refs.personalInfoForm)
            {
                self.$refs.personalInfoForm.reset()
                self.$router.push({name:'field-course-info',params:{ id: self.docId,name:self.$route.params.name }})
            }
        })
         db.collection(dbCollections.COLLEGES).orderBy('createdAt').get().then((querySnapshot)=>{
                this.allCollges = [];
                this.fieldUsedCollege = []
                querySnapshot.forEach(doc=>{
                    // if(doc.data().isDeleted == false){
                        if(doc.data().courses.length > 0){
                            doc.data().courses.forEach(courseData=>{
                                if(courseData.fieldName.id == self.docId){
                                    this.fieldUsedCollege.push(courseData)
                                }
                            })
                        }
                        this.allCollges.push(doc.data())
                    // }
                })
            }).catch(error=>{
                console.log(error)
            })
        

        //get All Fields
        setTimeout(()=>{
             db
            .collection(dbCollections.FIELDANDCOURSE)
            .orderBy('createdAt')
            .get()
            .then((querySnapshot)=>{
                querySnapshot.forEach(doc=>{
                    // if(!doc.data().isDeleted)
                    // {
                        if(self.docId==doc.id)
                        {
                           if(this.allCollges.length > 0){
                               let usedcourse = []
                                self.fieldName = doc.data().fieldName
                                self.currentFieldName = doc.data().fieldName
                                 doc.data().courses.forEach(data=>{
                                    // if(data.isCoursesDeleted == undefined || !data.isCoursesDeleted) {
                                        let obj = {
                                            name:data.name,
                                            id:data.id,
                                            collegeAdded:false,
                                            isCoursesDeleted: data.isCoursesDeleted !== undefined ? data.isCoursesDeleted : false,
                                            fromLocal:false
                                        }
                                        if(this.fieldUsedCollege.length > 0){
                                            let searchInd = this.fieldUsedCollege.findIndex(fdata=>{
                                                return fdata.courseName.id == obj.id
                                            })
                                            if(searchInd > -1){
                                                obj.collegeAdded = true;
                                            }
                                        }
                                        self.courses.push(obj)
                                        // }
                                    })
                                    self.dbCourseArray = JSON.parse(JSON.stringify(self.courses))
                           }
                           else
                           {   
                            self.fieldName = doc.data().fieldName
                            self.currentFieldName = doc.data().fieldName

                            doc.data().courses.forEach(data=>{
                                self.courses.push({
                                    name:data.name,
                                    id:data.id,
                                    collegeAdded:false
                                })
                            })
                           }
                            // console.log("Courses",doc.data().courses)
                        }
                        self.fields.push(doc.data().fieldName)
                    // }
                })
            })
            .catch(error=>{
                console.log(error)
            })
        },1000)       
    },
    methods: {
        alreadyExists(name,ind)
        {
            var self = this
            var found = false
            console.log("Name",name)
            self.courses.forEach((names,index)=>{
                if(names.name.toLowerCase().trim()==name.toLowerCase().trim() && ind != index &&  ind>=index)
                {
                    found = true
                    // console.log("Already Existing.")
                }
            })
            return found
        },
        addRow()
        {
            this.$refs.personalInfoForm.validate().then(success=>{
                if(success && !this.duplicateCourseName)
                {
                    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                    var charactersLength = characters.length;
                    var resultStr = ""
                    for ( var i = 0; i < 10; i++ ) {
                        resultStr += characters.charAt(Math.floor(Math.random() * charactersLength));
                    }
                    // console.log("Id",resultStr)
                    this.courses.push({name:'',id:resultStr,collegeAdded:false,isCoursesDeleted:false,fromLocal:true})
                }
            })
        },
        deleteRow(index,item,savedata)
        {
            var self = this;
            if(savedata == false) {
                this.courses.splice(index,1);
                return
            }
            // if(item.collegeAdded == false){
                this.dbCourseArray[index].isCoursesDeleted = !this.dbCourseArray[index].isCoursesDeleted
                if(item.fromLocal == true) {
                    this.courses.splice(index,1);
                } else {
                    self.dbCourseArray.map((ele)=>{
                        return delete ele.fromLocal
                    })
                    db
                        .collection(dbCollections.FIELDANDCOURSE)
                        .doc(self.docId)
                        .update({
                            updatedAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                            fieldName:self.fieldName,
                            courses:self.dbCourseArray
                        })
                        .then(()=>{     
                            this.courses[index].isCoursesDeleted = !this.courses[index].isCoursesDeleted          
                            self.$root.$emit('showToastMessage','Field and courses updated successfully.','success')
                            self.$root.$emit('stopSpinner')
                        })
                        .catch(error=>{
                            console.log(error)
                            self.$root.$emit('showToastMessage',error.message,'danger')
                            self.$root.$emit('stopSpinner')
                        })
                }
            // }else{
            //     console.log(item)
            //     // alert('course used in college')
            //     this.$root.$emit('showToastMessage','These course use in another documents','danger')
                
            // }
        },
        saveFieldCourse(redirect = true) {
            var self = this
            self.$refs.personalInfoForm.validate().then(success => {
                if (success && !self.duplicateCourseName && !self.fieldAlreadyExists) {
                    self.courses.map((ele)=>{
                        return delete ele.fromLocal
                    })
                    db
                        .collection(dbCollections.FIELDANDCOURSE)
                        .doc(self.docId)
                        .update({
                            updatedAt:firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                            fieldName:self.fieldName,
                            courses:self.courses
                        })
                        .then(()=>{               
                            if(redirect) {
                                self.$router.push({name:'field-course-info',params:{ id: self.docId,name:self.$route.params.name }})
                            }             
                            self.$root.$emit('showToastMessage','Field and courses updated successfully.','success')
                            self.$root.$emit('stopSpinner')
                        })
                        .catch(error=>{
                            console.log(error)
                            self.$root.$emit('showToastMessage',error.message,'danger')
                            self.$root.$emit('stopSpinner')
                        })
                }
                else
                {
                    console.log("Success",success)
                    self.$root.$emit('stopSpinner')
                }
            })
        },
        searchCourseCollege(collegeObj,fieldId,cb){         
            if(collegeObj.courses.length > 0){
                let searchIndex = collegeObj.courses.findIndex(elem=>{
                    return elem.fieldName.id == fieldId
                })
                if(searchIndex > -1){
                    cb(true)
                }else{
                cb(false)
                }
            }else{
                cb(false)
            }
        }
    },
    computed:{
        duplicateCourseName()
        {
            var self = this

            var duplicate = false

            for (let i = 0; i < self.courses.length; i++) {
                for (let j = i+1; j < self.courses.length; j++) {
                    if(self.courses[i].name.toLowerCase()==self.courses[j].name.toLowerCase())
                    {
                        duplicate = true
                    }
                }
            }

            return duplicate
        },
        fieldAlreadyExists()
        {
            var self = this

            var duplicate = false

            self.fields.forEach(data=>{
                if(data.toLowerCase()==self.fieldName.toLowerCase() && self.fieldName.toLowerCase()!=self.currentFieldName.toLowerCase())
                {
                    duplicate = true
                }
            })
            return duplicate;
        }
    }
}
</script>
<style>
.focusRemoveToggle:focus {
  outline: none !important;
}
</style>
<style scoped>
.edit_course.position-relative.table-responsive {
    padding: 0px!important;
}
</style>